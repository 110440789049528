<template>
  <Dialog
    v-loading="isLoading"
    :name="name"
    width="420px"
    class-own="dialog-apply-samples"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">
        Добавление шаблона правил вызова
      </div>
    </template>

    <div class="m-b-25">
      <div class="hardWarning">
        <div class="hardWarning__button">
          <Icon name="IconAttentionCircle" />
        </div>

        <div class="hardWarning__text">
          {{ warningText }}
        </div>
      </div>

      <template v-if="hasSamples">
        <div class="samples__title">
          Выберите день, на который применится шаблон
        </div>
        <el-form ref="applyForm" :model="applyForm" :rules="rules">
          <div class="samples__radio">
            <el-radio
              v-model="useForPeriod"
              :label="false"
              @change="changePeriod"
            >
              Применить на день
            </el-radio>

            <el-radio v-model="useForPeriod" :label="true">
              Применить на период
            </el-radio>
          </div>

          <el-form-item prop="applyDateStart">
            <el-date-picker
              v-model="applyForm.applyDateStart"
              :picker-options="options"
              type="date"
              format="dd.MM.yyyy"
              value-format="timestamp"
              placeholder="Укажите дату"
              size="large"
              class="apply__datepicker"
              :clearable="false"
              @change="applyForm.applyDateEnd = ''"
              @focus="clearValidate('applyDateStart')"
            />
          </el-form-item>
          <el-collapse-transition>
            <el-form-item v-if="useForPeriod" prop="applyDateEnd">
              <el-date-picker
                v-model="applyForm.applyDateEnd"
                :picker-options="pickerOptionsDateTo"
                type="date"
                format="dd.MM.yyyy"
                value-format="timestamp"
                placeholder="Укажите дату"
                size="large"
                class="apply__datepicker"
                :clearable="false"
                @focus="clearValidate('applyDateEnd')"
              />
            </el-form-item>
          </el-collapse-transition>
        </el-form>
      </template>
    </div>

    <div class="sample__buttons">
      <iq-mobile-button
        button-style="padding: 9px; width: 100%; cursor: pointer"
        text-style="color: #000000; font-weight: 500"
        :title="titleCancel"
        @onClick="closeDialog"
      />
      <iq-mobile-button
        button-style="padding: 9px; width: 100%; background: #DB6D39; border-color: #DB6D39; cursor: pointer"
        text-style="color: white; font-weight: 500"
        :title="titleOk"
        @onClick="handleApplySamples"
      />
    </div>
  </Dialog>
</template>

<script>
import {
  FETCH_SUPERVISOR_QUOTAS_LINES,
  GET_SUPERVISOR_QUOTAS_FOR_TABLE,
  GET_TERMINAL_CURRENT_ID,
} from '@/store/actions'
import { SUPERVISOR_APPLY_SAMPLES } from '@/constants/dialogs'
import { applyModalRules } from './data'
import { getLocalBriefDate, pickerOptions, startOfTzToday } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import { showMessageSuccess } from '@/UI/message'
import Api from '@/api'
import Dialog from '@/UI/dialog/Dialog'
import Icon from '@/UI/icon/Icon.vue'
import IqMobileButton from '@/UI/form/mobileButton/IqMobileButton'

export default {
  name: 'DialogApplySupervisorSamples',
  components: { Icon, IqMobileButton, Dialog },
  data() {
    return {
      name: SUPERVISOR_APPLY_SAMPLES,
      applyForm: {
        applyDateStart: startOfTzToday(),
        applyDateEnd: '',
      },
      options: pickerOptions,
      pickerOptionsDateTo: {
        disabledDate: time => this.applyForm?.applyDateStart > time.getTime(),
      },
      useForPeriod: false,
      isLoading: false,
      rules: applyModalRules,
    }
  },
  computed: {
    ...mapGetters({
      currentTerminalId: GET_TERMINAL_CURRENT_ID,
      quotasList: GET_SUPERVISOR_QUOTAS_FOR_TABLE,
    }),
    hasSamples() {
      return Boolean(this.getDialog(this.name)?.data)
    },
    warningText() {
      return this.hasSamples
        ? 'ОБЯЗАТЕЛЬНО проверьте актуальность ВСЕХ ПРАВИЛ ВЫЗОВА по суточному шаблону\n' +
            '          перед нажатием "Добавить". После применения шаблона правила сразу же\n' +
            '          вступают в силу!'
        : 'У вас нет шаблона правил вызова. Для применения шаблона его необходимо вначале настроить!'
    },
    titleCancel() {
      return this.hasSamples ? 'Отменить' : 'Понятно'
    },
    titleOk() {
      return this.hasSamples ? 'Добавить' : 'Настроить шаблон'
    },
  },
  watch: {
    currentTerminalId() {
      this.applyForm.applyDateStart = startOfTzToday()
    },
  },
  methods: {
    ...mapActions({
      refreshPage: FETCH_SUPERVISOR_QUOTAS_LINES,
    }),
    handleApplySamples() {
      if (this.hasSamples) {
        this.$refs.applyForm.validate(async valid => {
          if (valid) {
            try {
              this.isLoading = true
              let dateTo = this.applyForm.applyDateEnd
                ? this.applyForm.applyDateEnd
                : this.applyForm.applyDateStart
              const params = {
                unload_id: this.currentTerminalId,
                date_from: getLocalBriefDate(this.applyForm.applyDateStart),
                date_to: getLocalBriefDate(dateTo),
              }

              await Api.supervisor.applySupervisorSamples(params)
              await this.refreshPage()
              showMessageSuccess('Шаблоны сутоных квот применены')
            } catch (e) {
              console.log(e.response.data)
            } finally {
              this.isLoading = false
              this.closeDialog()
            }
          }
        })
      } else {
        let route = ['SupervisorTable', 'Supervisor'].includes(this.$route.name)
          ? 'SupervisorSamples'
          : 'AdminSupervisorSamples'

        this.$router.push({ name: route })
        this.closeDialog()
      }
    },
    changePeriod() {
      this.applyForm.applyDateEnd = ''
    },
    clearValidate(field) {
      this.$refs.applyForm.clearValidate(field)
    },
    closeDialog() {
      this.setDialog({ name: this.name }, false)
    },
  },
}
</script>

<style lang="sass" scoped>
.dialog-apply-samples
  .hardWarning
    display: flex
    padding: 12px
    margin-bottom: 15px
    background: #FEF0F0
    border: 1px solid #FDE2E2
    border-radius: 4px
    &__button
      margin-right: 10px
      svg
        width: 20px !important
        height: 20px !important
    &__text
      padding-top: 2px
      font-size: 14px
      line-height: 22px
  .samples__title
    margin-bottom: 5px
    font-size: 14px
    line-height: 22px
  .samples__radio
    display: flex
    gap: 25px
    margin: 10px 0
  .sample__buttons
    display: flex
    gap: 16px
  .apply__datepicker
    width: 100%
</style>
